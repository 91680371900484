import React, { useState } from 'react'
import { Dialog, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import covidImg from 'static/covid.png'

let open = true

const __setOpen = val => (open = val)

const PromoDialog = () => {
  const [_open, _setOpen] = useState(true)

  const setOpen = () => {
    _setOpen(false)
    __setOpen(false)
  }

  return (
    <Dialog onClose={() => setOpen(false)} open={open}>
      <IconButton
        style={{
          position: 'absolute',
          right: 0,
          margin: '3px',
          background: '#fff'
        }}
        aria-label="close"
        onClick={() => setOpen(false)}
      >
        <CloseIcon />
      </IconButton>
      <div>
        <img
          className="object-contain w-full max-w-lg max-h-screen"
          src={covidImg}
          alt="Open for Dental Emergency"
        />
      </div>
    </Dialog>
  )
}

export default PromoDialog
